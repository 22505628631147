import _implementation from "./implementation";
var exports = {};
var implementation = _implementation;

exports = function getPolyfill() {
  if (Number.isNaN && Number.isNaN(NaN) && !Number.isNaN("a")) {
    return Number.isNaN;
  }

  return implementation;
};

export default exports;