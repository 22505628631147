import _defineProperties from "define-properties";
import _polyfill from "./polyfill";
var exports = {};
var define = _defineProperties;
var getPolyfill = _polyfill;
/* http://www.ecma-international.org/ecma-262/6.0/#sec-number.isnan */

exports = function shimNumberIsNaN() {
  var polyfill = getPolyfill();
  define(Number, {
    isNaN: polyfill
  }, {
    isNaN: function testIsNaN() {
      return Number.isNaN !== polyfill;
    }
  });
  return polyfill;
};

export default exports;